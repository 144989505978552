import React from "react";
import { parseCustomDate } from "./DateManager";

//tri par defaut par date de creation decroissante
// Fonction pour trier les données
export function sortData(data, field, order) {
  return data.sort((a, b) => {
    const dateA = parseCustomDate(a[field]);
    const dateB = parseCustomDate(b[field]);
    return order === "asc" ? dateA - dateB : dateB - dateA;
  });
}

// trie en ordre croissant ou décroissant
export function toggleSort(
  field,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder
) {
  if (sortField === field) {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  } else {
    setSortField(field);
    setSortOrder("asc");
  }
}

// Applique les filtres
export function applySort(filteredData, sortField, sortOrder) {
  if (!sortField) return filteredData;

  return filteredData.sort((a, b) => {
    const isDateField = sortField === "oldestDocDate";

    // Fonction pour extraire la valeur selon sortField
    const getFieldValue = (item) => {
      if (isDateField) {
        // Récupérer la date la plus ancienne dans docsByStatus
        const dates = item.docsByStatus
          ?.map((statusData) => new Date(statusData.oldestDocDate))
          .filter((date) => !isNaN(date));
        return dates && dates.length > 0 ? Math.min(...dates) : null;
      }

      if (
        [
          "iCreatedDocs",
          "iExpectedDocs",
          "iUploadedDocs",
          "iCompletedDocs",
        ].includes(sortField)
      ) {
        // Récupérer une valeur numérique si présente dans l'objet principal
        return item[sortField] ? parseInt(item[sortField], 10) : 0;
      }

      if (sortField === "docsCount") {
        // Somme des documents dans docsByStatus
        return item.docsByStatus
          ?.reduce((sum, statusData) => sum + (statusData.docsCount || 0), 0);
      }

      if (sortField === "Status") {
        // Concaténer tous les statuts pour un tri textuel
        return item.docsByStatus?.map((statusData) => statusData.status).join(",");
      }

      // Valeur par défaut pour les champs simples
      return item[sortField];
    };

    const valueA = getFieldValue(a);
    const valueB = getFieldValue(b);

    if (valueA === valueB) return 0;
    if (valueA === null || valueA === undefined) return sortOrder === "asc" ? 1 : -1;
    if (valueB === null || valueB === undefined) return sortOrder === "asc" ? -1 : 1;

    // Comparaison pour les champs numériques ou dates
    if (isDateField || typeof valueA === "number") {
      return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    }

    // Comparaison pour les champs textuels
    const strA = valueA.toString().toLowerCase();
    const strB = valueB.toString().toLowerCase();
    return sortOrder === "asc"
      ? strA.localeCompare(strB)
      : strB.localeCompare(strA);
  });
}

