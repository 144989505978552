import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API
let docViewWindow = null;



export async function getJdata( instanceId, docId, token) {

    const apiUrl =`${API_BASE_URL}/document/query`;
    try {
        const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
            Authorization: token
            ,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ DocumentId: docId, InstanceId: instanceId }),
        });
    
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log(data.message.Data);
        displayFormattedJSON(data);
        return data.message.Data;
    } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
    }
}
const displayFormattedJSON = (jsonData) => {
    // Création d'une fenêtre modale pour afficher les données JSON
    const modal = document.createElement("div");
    modal.style.position = "fixed";
    modal.style.top = "0";
    modal.style.left = "0";
    modal.style.width = "100%";
    modal.style.height = "100%";
    modal.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    modal.style.display = "flex";
    modal.style.alignItems = "center";
    modal.style.justifyContent = "center";
    modal.style.zIndex = "9999";

    // Création d'un élément pour afficher les données JSON
    const jsonContainer = document.createElement("pre");
    jsonContainer.style.backgroundColor = "#333333";
    jsonContainer.style.color = "#ffffff";
    jsonContainer.style.padding = "20px";
    jsonContainer.style.overflow = "auto";
    jsonContainer.style.maxWidth = "80%";
    jsonContainer.style.maxHeight = "80%";
    jsonContainer.style.borderRadius = "5px";
    jsonContainer.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.3)";
    jsonContainer.textContent = JSON.stringify(jsonData, null, 2);

    // Ajout de l'élément des données JSON à la fenêtre modale
    modal.appendChild(jsonContainer);

    // Ajout de la fenêtre modale au corps du document
    document.body.appendChild(modal);

    // Fermer la fenêtre modale lorsque l'utilisateur clique en dehors des données JSON
    modal.addEventListener("click", (event) => {
      if (event.target === modal) {
        document.body.removeChild(modal);
      }
    });
  };