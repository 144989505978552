/** @format */

import React, { useState, useEffect } from "react";
import LotStat from "../LotStat/LotStat";
import Cookies from "js-cookie";
import { getInstance } from "../../../../API/DocQuery";
import DatePicker from "react-datepicker";
import NavBar from "../../../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";
import {faSortUp, faSortDown, faSearch, faCopy,} from "@fortawesome/free-solid-svg-icons";
import { getModel } from "../../../../API/ModelList";
import "react-datepicker/dist/react-datepicker.css";
import {DownloadCSVFile, DownloadCSVDoc} from "../../../Utils/FileDownloadManager";
import {formatCustomDate, parseCustomDate} from "../../../Utils/DateManager";
import {applyFilters, filterByModel, handleResetFilters} from "../../../Utils/FiltersManager";
import { handleCopy } from "../../../Utils/CopyDocID";
import { sortData, toggleSort, applySort } from "../../../Utils/SortManager";
import "../TabLots/TabLots.css";
import { setEnvironmentColors } from "../../../Utils/EnvironnementColor";
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styles from "../DefaultCSS.json"
 

const DefaultLots = () => {
  //Récupération des filtres sauvegardés depuis le localstorage
  const savedFiltersString = localStorage.getItem("filters");
  //valeurs par défaut des filtres
let savedFilters = {
  sInstanceName: "",
  sStatus: "",
  sFinalCustomer: "",
  dCreationDate: null,
  iCreatedDocs: null,
  iExpectedDocs: null,
  iUploadedDocs: null,
  iCompletedDocs: null
};

//Parssage des filtres sauvegardés du localstorage
if (savedFiltersString) {
  try {
    savedFilters = JSON.parse(savedFiltersString);
  } catch (error) {
    console.error("Error parsing saved filters from localStorage", error);
  }
}
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [filter, setFilter] = useState(savedFilters);
  const [sortField, setSortField] = useState("dCreationDate");
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' ou 'desc'
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [uniqueModels, setUniqueModels] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [colorFilter, setcolorFilter] = useState("#235073");
  const [colorFilterAction, setcolorFilterAction] = useState("#ffffff");
  const [filteredData, setFilteredData] = useState(data);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: null,
    toDate: null,
    filterType: "between",
  });
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const DatePickerStyle = {
    width: '400px',
    height: '200px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    background: 'white',
    border: '10px solid #183152',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  //fonction pour basculer la visibilité
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  //sauvegarder les filtres dans le localstorage
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  //Chargement des données et des modèles à partir de l'API
  useEffect(() => {
    const idToken = Cookies.get("Token"); // Récupérer le token depuis les cookies
    const modelid = Cookies.remove("modelId");
    const loadAndProcessData = async () => {
      try {
        const responseData = await getInstance(idToken);
        const loadModelData = async () => {
          const modelData = await getModel(idToken);
          setModelName(
            modelData.map((model) => ({
              id: model.ModelId,
              name: model.ModelName,
              component: model.ModelComponent              
            })), 
          );          
          // Model is not chosen yet                      
          if (modelData.length === 1) {
            // Only one model available              
            autoSelectElement(modelData[0]); 
          }
        }; 
        loadModelData();
        if (Array.isArray(responseData)) {
          setData(responseData);
          const loadedStatuses = responseData.map((item) => item.sStatus);
          setUniqueStatuses([...new Set(loadedStatuses)]);
          const loadedModels = responseData.map((item) => item.sModelId);
          setUniqueModels([...new Set(loadedModels)]);
        } else {
          console.error(
            "Les données chargées ne sont pas un tableau",
            responseData
          );
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      }
    };

    loadAndProcessData();
  }, []);

  //Sélectionne automatiquement un élément en fonction du modèle
  const autoSelectElement = (model) => {
    //console.log("Model",model);
    Cookies.set("modelId", model.ModelId);
    const routepath = `/User/${model.ModelComponent}Lots`
    const location = window.location
    if (model.ModelId && location.pathname != routepath) {          
      navigate(routepath);      
    }
  }

  // Charger les filtres sauvegardés depuis le localStorage
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem("filters"));
    if (savedFilters) {
      setFilter(savedFilters);
    }
  }, []);
  
  // Sauvegarder les filtres dans le localStorage
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  //Appliquer le tri de lorsque le champ de l'ordre de  tri change
  useEffect(() => {
    if (sortField === 'dCreationDate') {
      setData((prevData) => sortData([...prevData], sortField, sortOrder));
    } else {
      setData((prevData) => applySort([...prevData], sortField, sortOrder));
    }
  }, [sortField, sortOrder]);

  //Gérer le changement de tri
  const handleSort = (field) => {
    toggleSort(field, sortField, setSortField, sortOrder, setSortOrder);
  };
  
  //Gérer le changement de modèle sélectionné
  const handleModelChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    
    if (selectedValue.id) {
      Cookies.set("modelId", selectedValue.id);
      navigate(`/User/${selectedValue.component}Lots`);
    } else {
            Cookies.set("modelId", "all");
            navigate(`/User/Lots`);
      }
    };

    //Change les couleurs en fonction de l'environnement
    useEffect(() => {
      setEnvironmentColors([
        [setcolorFilter, "colorFilter"],
        [setcolorFilterAction, "colorFilterAction"]
      ]);
    }, []); 


    // Appliquer les filtres et mettre à jour filteredData
    useEffect(() => {
      const dataAfterFilters = applyFilters(data, filter, dateRangeFilter, parseCustomDate);
      const dataAfterModelFilter = filterByModel(dataAfterFilters, filter);
      setFilteredData(dataAfterModelFilter);
    }, [data, filter, dateRangeFilter, parseCustomDate]);
  
    const { t } = useTranslation();

        return (
          <>
          {/*Ajout de la nav bar*/}
      <NavBar />
      <div className='page-container' style={{ backgroundColor: "#F7F9F9" }}>
      <div className="div_ariane_stats">
        <div style={{ display: "flex", alignItems: "left" }}>
          <h6 style={{ marginRight: "10px", marginTop: "10px", marginLeft: "10px", color: "#235073" }}>Instances</h6>
          <p style={{ marginTop: "10px" }}>{" > "}</p>
          {/*Fil d'ariane qui permet de changer de modèle */}
          <select
            className="form-select"
            id="modelName"
            //value={selectedModel}
            onChange={handleModelChange}
            style={{ width: "auto", height: "30px", fontSize: "14px", marginTop: "4px", marginLeft: "5px", backgroundColor: "#F7F9F9", color: "#235073", fontWeight: "bold" }} // Ajustez la taille ici        
            >
            <option value='{}'>{t("AllModels")}</option>
            {modelName.map((model) => (
              <option
                key={model.id}
                value={JSON.stringify({
                  id: model.id,
                  component: model.component,
                  name: model.name,
                })}
                //value={model.id}
                >
                {model.name}
              </option>
            ))}
          </select>         
        </div>
          { /* Affichage des statistiques dans un doughnut */}   
          <LotStat lots={applySort(applyFilters(data, filter, dateRangeFilter, parseCustomDate))} />
      </div>
          
          {/* Partie invisible du site */}
          {isVisible && (
          <div style={DatePickerStyle}>
            <table style={{width:'300px'}}>
              <tbody>
                <tr>
                  <td style={{width:'100px',textAlign:'left'}}>            
                    <label>Date de début :</label>
                  </td>
                  <td style={{width:'100px'}}>            
                    <DatePicker
                      selected={dateRangeFilter.fromDate}
                      onChange={(date) =>
                        setDateRangeFilter({ ...dateRangeFilter, fromDate: date })
                      }
                      dateFormat='yyyy-MM-dd'
                      className='form-control'
                      />
                  </td>
                </tr>
                <tr>
                  <td style={{width:'100px',textAlign:'left'}}>            
                    <label>Date de fin :</label>
                  </td>
                  <td style={{width:'100px'}}>            
                    <DatePicker
                      selected={dateRangeFilter.toDate}
                      onChange={(date) =>
                        setDateRangeFilter({ ...dateRangeFilter, toDate: date })
                      }
                      dateFormat='yyyy-MM-dd'
                      className='form-control'
                      />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{height:'50px',alignItems:'center', justifyContent:'center'}}><center><button onClick={toggleVisibility}>Fermer</button></center></td>                
                </tr>
              </tbody>
            </table>            
          </div>
          )}


        <div
          className='table-responsive'
          style={{
            padding: "20px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.7",
          }}
        >

          {/* Tableau avec les différentes data des intances */}
          <table className='table table-striped'>
            <thead style={{ position: "sticky", top: 0, zIndex: 2}}>
              {/* Premier header avec le nom des différentes actions */}
              <tr style={{ textAlign: "center", verticalAlign: "middle", height:"25px", backgroundColor:colorFilter, borderBottom:"none" }}>
                <th style={ styles.tabLots }>
                  Actions
                </th>
                <th style={ styles.tabLots }>
                  {t("Models")}
                </th>
                <th colSpan='2' style={ styles.tabLots }>
                  Instance
                </th>
                <th style={ styles.tabLots }>
                <label onClick={toggleVisibility}>Création</label>
                </th>
                <th style={ styles.tabLots }>
                  {t("Status")}
                </th>
                <th style={ styles.tabLots }>
                  {t("End customer")}
                </th>
                <th style={ styles.tabLots }>
                  {t("Docs expected")}
                </th>
                <th style={ styles.tabLots }>
                  {t("Docs created")}
                </th>
                <th style={ styles.tabLots }>
                  {t("Docs uploaded")}
                </th>
                <th style={ styles.tabLots }>
                  {t("Docs completed")}
                </th>
              </tr>
            </thead>
            <tbody style={{overflowY: "auto"}}>
            {/* Deuxième header avec les différentes fonctions qui permette le tri et le filtres des datas */}
            <tr style={{ textAlign: "center", verticalAlign: "middle", backgroundColor:colorFilterAction, borderTop:"none", opacity:"0.90" }}>
                {/* Bouton qui permet de réinitialiser les filtres en supprimant les données du local storage et recharge la page */}
                <th style={{ width: "100px",...styles.tabLots }}>
                  <div style={{display:"flex"}}>  
                      <div>
                        <DownloadCSVFile data={data}/>
                      </div>
                      <button
                      data-toggle="tooltip"
                      title={t("Reset filters")} 
                      onClick={handleResetFilters}
                      className="btn btn-outline-primary btn-sm bg-light btn-hover-blue">
                      RAZ
                    </button>
                    <button
                    data-toggle="tooltip"
                    title={t("Reload page")}
                    className="btn btn-outline-primary bg-light btn-hover-blue"
                    onClick={() => window.location.reload(false)}>
                      <FontAwesomeIcon icon={faArrowRotateRight} />
                    </button>
                    </div>
                </th>
                {/* Select qui permet de choisir un modèle */}
                <th style={styles.tabLots}>
                  <select
                    className='form-select filter-select'
                    onChange={(e) =>
                      setFilter({ ...filter, sModelId: e.target.value })
                    }
                    style={{ width: "125px", textAlign: "left", height:"31px"}}
                    
                  >
                    <option value=''>{t("Models")}</option>
                    {uniqueModels.map((model) => (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    ))}
                  </select>
                </th>
                {/* Input pour chercher une instance*/}
                <th
                  style={{ width: "300px",...styles.tabLots }}
                  colSpan='2'
                >
                  {" "}
                  <input
                    type='text'
                    name='name'
                    placeholder={t("Instance name")}
                    className='form-control filter-input placeholder-font' // Ajout de la classe filter-input
                    value={filter.sInstanceName}
                    onChange={(e) =>
                      setFilter({ ...filter, sInstanceName: e.target.value })
                    }
                    style={{ width: "300px", height:"31px"}}
                  />
                </th>
                {/* onclick qui permet de trier les dates par ordre croissant ou décroissant, et popup qui permet de trier en fonction de deux dates */}
                <th
                  style={{ width: "100px",...styles.tabLots }}
                  onClick={() => handleSort("dCreationDate")}
                >
                  {sortField === "dCreationDate" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                {/* Select qui permet de choisir un status */}
                <th style={styles.tabLots}>
                  <select
                    className='form-select filter-select'
                    onChange={(e) =>
                      setFilter({ ...filter, sStatus: e.target.value })
                    }
                    style={{ width: "100px", textAlign: "left", height:"31px" }}
                  >
                    <option value=''>{t("Status")}</option>
                    {uniqueStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </th>
                {/* Input qui permet de chercher un client final*/}
                <th style={{ width: "150px" ,...styles.tabLots}}>
                  <input
                    type='text'
                    placeholder={t("End customer")}
                    className='form-control me-2 filter-input placeholder-font'
                    onChange={(e) =>
                      setFilter({ ...filter, sFinalCustomer: e.target.value })
                    }
                    style={{ width: "150px", height:"31px" }}
                  />
                </th>
                {/*onclick qui tri en ordre dsc ou crs les docs attendus*/}
                <th
                style={styles.tabLots}
                  onClick={() => handleSort("iExpectedDocs")}
                >
                  {sortField === "iExpectedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                {/*onclick qui tri en ordre dsc ou crs les docs crées*/}
                <th
                  style={styles.tabLots}
                  onClick={() => handleSort("iCreatedDocs")}
                >
                  {sortField === "iCreatedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                {/*onclick qui tri en ordre dsc ou crs les docs uploadés*/}
                <th
                style={styles.tabLots}
                  onClick={() => handleSort("iUploadedDocs")}
                >
                  {sortField === "iUploadedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                {/*onclick qui tri en ordre dsc ou crs les docs terminés*/}
                <th
                  style={styles.tabLots}
                  onClick={() => handleSort("iCompletedDocs")}
                >
                  {sortField === "iCompletedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
              </tr>

            {/*Fonction qui permette de filtrer*/}
            {applySort(
              filterByModel(
                applyFilters(data, filter, dateRangeFilter, parseCustomDate), // Ajout de filterByModel
                filter
              ),
              sortField,
              sortOrder
            )
            .slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage
            )
                .map((item) => (
                 // ligne d'affichage des datas
                  <tr
                  key={item.kInstanceId}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/*Permet de download une instance*/}
                      <div
                      data-toogle="tooltip"
                      title={t("Download instance")}>
                        <DownloadCSVDoc key={item.kInstanceId} item={item} />
                      </div>
                      {/*Permet d'accèder à une instance*/}
                      <button
                        data-toogle="tooltip"
                        title={t("View instance")}
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => {
                          // Définir un cookie
                          Cookies.set("instanceId", item.kInstanceId); // Ajout d'une expiration (facultatif)
                          Cookies.set("instanceName", item.sInstanceName);
                          navigate(`/User/Docs`);
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </td>
                    {/*Affichage du modelID*/}
                    <td style={{width: "100px" }}>                      
                      {item.sModelId}                      
                    </td>
                    {/*Affichage de l'instance name */}
                    <td style={{ width:"300px" }}>
                      <span
                        style={{
                          //fontSize: "1.2em",
                          marginLeft: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.sInstanceName}
                      </span>
                    </td>
                    {/*Boutton pour copier l'instanceId */}
                        <td>
                        <CopyToClipboard
                        text={item.kInstanceId}
                        onCopy={() => handleCopy(item.kInstanceId)}
                      >
                        <button className='btn btn-outline-primary btn-sm' data-toogle="tooltip" title={t("Copy instanceId")}>
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </CopyToClipboard>
                        </td>
                        {/*Affichage de la date de création dans un format précis */}
                    <td
                      style={{ lineHeight:'1.5' }}
                    >
                      {formatCustomDate(item.dCreationDate)}
                    </td>
                        {/*Affichage du status */}
                    <td style={{ width: "100px" }}>                      
                      {item.sStatus}                      
                    </td>
                        {/*Affichage du client final */}
                    <td>
                      <span
                        style={{
                          fontSize: "1.2em",
                          fontWeight: "bold",
                        }}
                      >
                        {item.sFinalCustomer}
                      </span>
                    </td>
                        {/*Affichage des docs attendus*/}
                    <td>
                      {item.iExpectedDocs}
                    </td>
                    {/*Affichage des docs crées */}
                    <td>
                      {item.iCreatedDocs}
                    </td>
                    {/*Affichage des docs uploadés */}
                    <td>
                      {item.iUploadedDocs}
                    </td>
                    {/*Affichage des docs terminés */}
                    <td>
                      {item.iCompletedDocs}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "10px",
          }}
        >
          {/*Affichage du nombre de pages en fonctions du nombre de docs*/}
          <div>
            {Array.from(
              { length: Math.ceil(filteredData.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  className='btn btn-outline-primary btn-sm me-2'
                  onClick={() => setCurrentPage(index + 1)}
                  style={{
                    fontWeight: currentPage === index + 1 ? "bold" : "normal",
                    backgroundColor: currentPage === index + 1 ? "#0d6efd" : "transparent",
                    color: currentPage === index + 1 ? "white" : "#0d6efd",
                  }}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLots;
