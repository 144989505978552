import React from "react";
import { toast } from "react-toastify";
import Cookies from 'js-cookie'
import { docView } from "../../API/DocView";
import { getJdata } from "../../API/DocJdata";

// Utilisation de Set pour sélectionner les documents cochés
export const handleCheckboxChange = (event, DocId, selectedDocuments, setSelectedDocuments) => {
    const isChecked = event.target.checked;
    const newSelectedDocuments = new Set(selectedDocuments);
  
    if (isChecked) {
      newSelectedDocuments.add(DocId);
    } else {
      newSelectedDocuments.delete(DocId);
    }
  
    setSelectedDocuments([...newSelectedDocuments]);
  };

//update les éléments selected
export const handleUpdateSelected = async (selectedDocuments, handleSubmitChange, setSelectedDocuments, data, instanceId, docUpdate) => {
  let errcount = 0;
  for (const DocId of selectedDocuments) {
    try {
      await handleSubmitChange(DocId, data, instanceId, docUpdate);
    } catch (error) {
      errcount++;
    }
  }

  // Check if there were some errors
  if (errcount !== 0) {
    toast.error(
      `${errcount} erreur(s) lors de la mise à jour des documents !`,
      {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  } else {
    // Notify the user that the update was successful
    // Reset selected documents after the update
    toast.success("Données mises à jour avec succès !", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setSelectedDocuments([]);
    // Reload page
    window.location.reload();
  }
};

// Gère le changement de fichier sélectionné et met à jour les états correspondants
export const handleFileChange = (e, setSelectedFile, setFileExtension) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileName = file.name;
      const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
      setFileExtension(fileExt);
      console.log("File extension is", fileExt);
    }
};  

// Gère le changement du nombre d'éléments par page
export const handleItemsPerPageChange = (event, setItemPerPage) => {
    setItemPerPage(Number(event.target.value));
};  

// Gère les changements d'entrée et met à jour les états correspondants
export const handleInputChange = (event, DocId, key, selectedDocuments, setSelectedDocuments, data, setData) => {
  const { value, type, checked } = event.target;
  if (type === "checkbox") {
    if (checked) {
      setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
    } else {
      setSelectedDocuments((prevSelected) => prevSelected.filter((id) => id !== DocId));
    }
  } else {
    const newValue = key === "isvalid" ? event.target.value === "true" : value;
    const newData = data.map((item) => {
      if (item.DocId === DocId) {
        const updatedItem = { ...item, [key]: newValue };
        const isAnyFieldModified = Object.keys(updatedItem).some(
          (field) => field !== "DocId" && item[field] !== updatedItem[field]
        );
        if (isAnyFieldModified) {
          setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
        } else {
          setSelectedDocuments((prevSelected) => prevSelected.filter((id) => id !== DocId));
        }
        console.log('Updateditem', updatedItem)
        return updatedItem;
      }
      return item;
    });
    console.log('New Data', newData)
    setData(newData);
  }
};

// Gère la soumission des modifications et met à jour les états correspondants
export const handleSubmitChange = async (DocId, data, instanceId, docUpdate) => {
  const updatedItem = data.find((item) => item.DocId === DocId);
  if (updatedItem) {
    const { Complexite, Priorite, isvalid } = updatedItem;
    const idToken = Cookies.get("Token");
    try {
      const response = await docUpdate(idToken, instanceId, DocId, Complexite, Priorite, isvalid);
      console.log("Mise à jour réussie", response);
      window.location.reload();
    } catch (error) {
      console.log("Erreur lors de la mise à jour des données", error);
    }
  }
};

// Fonction pour récupérer les modèles de données
export const callGetModel = (docId, modelId) => {
  const token = Cookies.get("Token");
  const instanceId = Cookies.get("instanceId");
  docView(token, docId, modelId, instanceId);
};

// Fonction pour récupérer lej data dun document
export const callGetJData = (docId) => {
  const token = Cookies.get("Token");
  const instanceId = Cookies.get("instanceId");
  getJdata(instanceId, docId, token);
};


