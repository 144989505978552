import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { docUpdate } from "../../../../API/DocUpdate";
import { formatDateForStorage } from "../../../Utils/DateManager"; 
import { updateInstanceData } from "../../../../API/UpdateInstance"

export const handleSubmitChange = async (DocId, data, instanceId) => {
  const updatedItem = data.find((item) => item.DocId === DocId);
  if (updatedItem) {
    const { Complexite, Priorite, isvalid } = updatedItem;
    const idToken = Cookies.get("Token");
    const newstatus = isvalid == false ? "invalid" : "qualified";

    console.log("Mise à jour avec les paramètres :", {
      idToken,
      instanceId,
      DocId,
      Complexite,
      Priorite,
      isvalid,
      newstatus,
    });

    try {
      const response = await docUpdate(
        idToken,
        instanceId,
        DocId,
        Complexite,
        Priorite,
        isvalid,
        newstatus
      );
      console.log("Mise à jour réussie", response);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données", error);
    }
  }
};

export const handleUpdateSelected = async (selectedDocuments, data, instanceId, setSelectedDocuments) => {
  var errcount = 0;
  for (const DocId of selectedDocuments) {
    try {
      await handleSubmitChange(DocId, data, instanceId);
    } catch (error) {
      errcount++;
    }
  }

  // Check if there were some errors ?
  if (errcount !== 0) {
    toast.error(
      `${errcount} erreur(s) lors de la mise à jour des documents !`,
      {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  } else {
    // Notifier l'utilisateur que la mise à jour est réussie
    toast.success("Données mises à jour avec succès !", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // Réinitialiser les documents sélectionnés après la mise à jour
    setSelectedDocuments([]);
    // Reload page
    window.location.reload();
  }
};

export const handleInputChange = (event, DocId, key, data, setData, setSelectedDocuments) => {
  const { value, type, checked } = event.target;
  
  if (type === "checkbox") {
    if (checked) {
      setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
    } else {
      setSelectedDocuments((prevSelected) =>
        prevSelected.filter((id) => id !== DocId)
      );
    }
  } else {
    const newValue =
      key === "isvalid" ? event.target.value === "true" : value;
    const newData = data.map((item) => {
      if (item.DocId === DocId) {
        const updatedItem = { ...item, [key]: newValue };
        const isAnyFieldModified = Object.keys(updatedItem).some(
          (field) => field !== "DocId" && item[field] !== updatedItem[field]
        );
        // Update the checkbox based on the modification
        if (isAnyFieldModified) {
          // If at least one modifiable field is modified, check the checkbox
          setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
        } else {
          // If no modifiable field is modified, remove the checkbox
          setSelectedDocuments((prevSelected) =>
            prevSelected.filter((id) => id !== DocId)
          );
        }
        return updatedItem;
      }
      return item;
    });
    // Update the state with the modified data
    setData(newData);
  }
};


export const handleInputChangeDate = (value, kInstanceId, key, data, setData, handleSubmitChangeInstance) => {
  console.log('event=', value);
  console.log("data", data);
  const newData = data.map((item) => {
    if (item.kInstanceId === kInstanceId) {
      return {
        ...item,
        [key]:
          key === "dDateRemise"
            ? value ? formatDateForStorage(new Date(value)) : ""
            : value,
      };
    }
    return item;
  });
  setData(newData);
  handleSubmitChangeInstance(newData, kInstanceId);
};


export const handleSubmitChangeInstance = async (newData, instanceId) => {
  console.log("TestNewdata : ", newData)
  const updatedItem = newData.find((item) => item.kInstanceId === instanceId);
  console.log("UpdatedItem", updatedItem);
  if (updatedItem) {
    const { dDateRemise, sStatus } = updatedItem; // Extraction des valeurs à envoyer
    console.log(dDateRemise, sStatus, instanceId);
    const idToken = Cookies.get("Token"); // Extraction du token
    try {
      const response = await updateInstanceData(
        idToken,
        instanceId,
        sStatus,
        dDateRemise
      );
      console.log("Réponse de la mise à jour: ", response);
      // alert ok
      // toast.success("Instance mise à jour avec succès!");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de l'instance");
      console.error("Erreur lors de la mise à jour des données", error);
    }
  }
};
