/** @format */

import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL;
//const token = localStorage.getItem("idToken");
//localStorage.getItem("idToken"),
export async function createInstanceAndDocs(
  instanceNameOrId, 
  modelId,
  files,
  token,
  isNewInstance, 
  json = null,
  iExpectedDocs = null, 
  sFinalCustomer = null 
) {
  try {
    let instanceId;

    // Vérifier si on doit créer une nouvelle instance ou utiliser une existante
    if (isNewInstance) {
      // Créez une nouvelle instance
      console.log("Création d'une nouvelle instance :", instanceNameOrId);
      const createInstanceResponse = await createInstance(
        instanceNameOrId,
        modelId,
        iExpectedDocs, 
        token,
        sFinalCustomer 
      );

      if (createInstanceResponse.statusCode !== 200) {
        throw new Error("Erreur lors de la création de l'instance.");
      }

      instanceId = createInstanceResponse.message.InstanceId; // ID de la nouvelle instance
      console.log("Nouvelle instance créée avec ID :", instanceId);
    } else {
      // Utiliser l'instance existante
      console.log("Ajout à l'instance existante :", instanceNameOrId);
      instanceId = instanceNameOrId; 
    }

    // Upload des documents dans l'instance (nouvelle ou existante)
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name;

      const createDocsResponse = await createDocs(
        instanceId,
        fileName,
        token,
        json
      );

      if (createDocsResponse.statusCode === 200) {
        const presignedUrl = createDocsResponse.message.PresignedURL;

        // Upload physique du fichier
        await uploadDoc(file, presignedUrl, token).then((response) => {
          console.log(`Fichier ${fileName} uploadé avec succès :`, response);
        });
      } else {
        throw new Error(
          `Erreur lors de la création du document pour le fichier ${fileName}.`
        );
      }
    }
  } catch (error) {
    console.error("Erreur dans createInstanceAndDocs :", error);
    throw new Error(error);
  }
}

export async function createInstance(
  instanceName,
  modelId,
  exeptedDocs,
  token,
  sFinalCustomer 
) {
  var instance = instanceName;
  var raw = JSON.stringify({
    ModelId: modelId,
    InstanceName: instance,
    ExpectedDocs: Number(exeptedDocs),
    FinalCustomer: sFinalCustomer, 
    Options: {
      OutputAs: "json",
    },
  });

  try {
    const response = await fetch(API_BASE_URL + "/process/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: raw,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

async function createDocs(instanceId, fileName, token, json = "") {
  const jsonData = json ? JSON.parse(json) : {};
  console.log("Envoi des données à l'API createDocs :", {
    InstanceId: instanceId,
    FileName: fileName,
    FileType: "application/pdf",
    InputData: jsonData,
  });
  try {
    const response = await fetch(API_BASE_URL + "/document/s3upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        InstanceId: instanceId,
        FileName: fileName,
        FileType: "application/pdf",
        InputData: jsonData,
      }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      console.error("Erreur API createDocs :", errorData);
      throw new Error(
        `Erreur HTTP lors de la création du document : ${response.status}`
      );
    }
    const data = await response.json();
    console.log("Réponse de l'API createDocs :", data);
    return data;
  } catch (error) {
    console.error("Erreur dans createDocs :", error);
    throw new Error(error);
  }
}

async function uploadDoc(file, signedUrl, token) {
  var mypresignedurl = signedUrl;
  var requestOptions = {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "application/pdf",
      "Content-Length": file.length,
    },
  };

  try {
    const response = await fetch(mypresignedurl, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.statusCode}`);
    }
  } catch (error) {
    throw new Error(error);
  }
}
