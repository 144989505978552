//imports

import React from "react";

let initialDocsByStatus = {};

//functions
// Applique les filtres aux données
export function applyFilters(
  data,
  filter,
  dateRangeFilter = null,
  parseCustomDate = null
) {
  // Si les données sont undefined ou null, retourne un tableau vide
  if (!Array.isArray(data)) {
    console.warn(
      "Les données sont nulles ou non définies, un tableau vide sera retourné."
    );
    return [];
  }

  console.log(data.map(item => item.isvalid));

  data.forEach(item => { 
    if (!item.docsByStatus) { 
      item.docsByStatus = []; 
    } 
    if (!initialDocsByStatus[item.ModelId]) { 
      initialDocsByStatus[item.ModelId] = [...item.docsByStatus]; 
    } 
    else { 
      item.docsByStatus = [...initialDocsByStatus[item.ModelId]]; 
    }});
    
  return data
    .filter((item) =>
      filter.FileName
        ? item.FileName?.toLowerCase().includes(filter.FileName.toLowerCase())
        : true
    )
    .filter((item) =>
      filter.FinalCustomer
        ? item.FinalCustomer?.toLowerCase().includes(
            filter.FinalCustomer.toLowerCase()
          )
        : true
    )
    .filter((item) =>
      filter.Status
        ? item.Status?.toLowerCase().includes(filter.Status.toLowerCase())
        : true
    )
    .filter((item) => {
      // Si le filtre isvalid est défini, appliquer le filtre
      if (filter.isvalid !== null && filter.isvalid !== undefined && filter.isvalid !== "") {
        return item.isvalid === (filter.isvalid === "true");
      }
      // Sinon, ne rien filtrer
      return true; 
    })
       
    .filter((item) =>
      filter.Complexite
        ? item.Complexite?.toLowerCase().includes(filter.Complexite.toLowerCase())
        : true
    )
    .filter((item) => {
      if (!Array.isArray(item.docsByStatus)) {
        console.warn(`docsByStatus is not an array for ModelId ${item.ModelId}`);
        return false; // Exclure les items sans docsByStatus valide
      }
    
      // Si un filtre de statut est appliqué
      if (filter.statusFilter) {
        // Filtrer les statuts correspondant au filtre et docsCount > 0
        const filteredDocs = item.docsByStatus.filter(
          (status) =>
            status.status === filter.statusFilter && status.docsCount > 0
        );
    
        // Si des statuts valides sont trouvés, garder uniquement ces statuts
        if (filteredDocs.length > 0) {
          item.docsByStatus = filteredDocs; // Met à jour temporairement pour l'affichage
          return true; // Conserve cet élément
        }
    
        return false; // Exclure les modèles sans statuts correspondants
      }
    
      // Si aucun filtre de statut n'est appliqué, conserver tous les éléments
      return true;
    })
    .filter((item) =>
      filter.sInstanceName
        ? item.sInstanceName
            ?.toLowerCase()
            .includes(filter.sInstanceName.toLowerCase())
        : true
    )
    .filter((item) => (filter.sStatus ? item.sStatus === filter.sStatus : true))
    .filter((item) =>
      filter.sFinalCustomer
        ? item.sFinalCustomer
            ?.toLowerCase()
            .includes(filter.sFinalCustomer.toLowerCase())
        : true
    )
    .filter((item) =>
      filter.ModelId
        ? item.ModelId?.toLowerCase().includes(filter.ModelId.toLowerCase())
        : true
    )
    // Boucle pour appliquer les filtres dynamiques
    .filter((item) => {
      for (let key in filter) {
        if (filter[key] !== null && filter[key] !== undefined) {
          if (!(key in item)) {
            console.warn(`La clé "${key}" n'existe pas dans les données.`);
            continue; // Ignore les clés qui n'existent pas
          }
    
          if (typeof item[key] === "boolean") {
            if (item[key] !== (filter[key] === "true")) {
              return false;
            }
          } else if (typeof filter[key] === "string") {
            if (!item[key]?.toString()?.toLowerCase().includes(filter[key].toLowerCase())) {
              return false;
            }
          }
        }
      }
      return true;
    })
    .filter((item) => {
      if (
        !dateRangeFilter ||
        !parseCustomDate ||
        (!dateRangeFilter.fromDate && !dateRangeFilter.toDate)
      )
        return true;
      const creationDate = parseCustomDate(item.dCreationDate);
      if (dateRangeFilter.fromDate && dateRangeFilter.toDate) {
        return (
          creationDate >= dateRangeFilter.fromDate &&
          creationDate <= dateRangeFilter.toDate
        );
      }
      return true;
    });
}


//Met a jour le type de filtre
export function updateFilterType(filterType, setDateRangeFilter) {
  setDateRangeFilter((prevFilter) => ({
    ...prevFilter,
    filterType: filterType,
  }));
}

// Fonction pour filtrer les données en fonction du modèle sélectionné
export function filterByModel(data, filter) {
  if (!filter.sModelId) {
    // Si aucun modèle n'est sélectionné, retourne toutes les données
    return data;
  }

  const filteredData = data.filter((item) => {
    const shouldInclude = item.sModelId === filter.sModelId;
    return shouldInclude;
  });

  return filteredData;
}

//permet de reset les filtres dans le localstorage et relance la page
export function handleResetFilters() {
  localStorage.clear();
  window.location.reload(false);
}
