/** @format */

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getDoc } from "../../../../API/DocListPlans";
import NavBar from "../../../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./TabDoc.css";
import "../TabDoc/doc.css";
import {
  faSortUp,
  faSortDown,
  faCopy,
  faFileAlt,
  faUpload,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import { getModel } from "../../../../API/ModelList";
//import { getAsanaUserList } from "../../../../API/asanaUserList"; // In cae we need it that's a good start...
import "react-datepicker/dist/react-datepicker.css";
import DocStat from "../LotStat/DocStats";
import Modal from "react-modal";
import { attachZip } from "../../../../API/AttachZip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import config from "./config.json"; // Charger la configuration
import {
  handleCheckboxChange,
  handleItemsPerPageChange,
  callGetModel,
} from "../../../Utils/DocsManager";
import {
  handleUpload,
  handleDownload,
} from "../../../Utils/FileDownloadManager";
import { handleCopy } from "../../../Utils/CopyDocID";
import { applyFilters } from "../../../Utils/FiltersManager";
import { applySort, toggleSort } from "../../../Utils/SortManager";
import { formatDateshort } from "../../../Utils/DateManager";
import { setEnvironmentColors } from "../../../Utils/EnvironnementColor";
import { useTranslation } from "react-i18next";
import styles from "../Plan01CSS.json";
import "react-toastify/dist/ReactToastify.css"; 
import { handleInputChange, handleUpdateSelected } from "../Utils/handlers";

const Plan01Doc = () => {
  const [modelInstanceStatuses, setModelInstanceStatuses] = useState([]);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(""); 
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    FinalCustomer: "",
    Status: "",
    isvalid: null,
  });
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [ModelName, setModelName] = useState([]);
  const [hover, setHover] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [modelId, setModelId] = useState();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const navigate = useNavigate();
  const instanceId = Cookies.get("instanceId");
  const instanceName = Cookies.get("instanceName");
  const [configData, setConfigData] = useState({});
  const itemNames = configData.itemNames || [];
  const itemVars = configData.itemVars || [];
  const [filteredData, setFilteredData] = useState([]);
  const [colorFilter, setcolorFilter] = useState("#235073");
  const [colorFilterAction, setcolorFilterAction] = useState("#ffffff");
  const [currentReviewURL, setCurrentReviewURL] = useState("");
  const [currentDocIndex, setCurrentDocIndex] = useState(null);
  const [currentDocName, setCurrentDocName] = useState("");

  //permet de récup des datas
  useEffect(() => {
    const idToken = Cookies.get("Token");
    const loadModelData = async () => {
      const modelData = await getModel(idToken);
      setModelName(modelData[0].ModelName);
      setModelId(modelData[0].ModelId);
      const instanceStatuses = modelData[0].ModelDocumentStatus;
      setModelInstanceStatuses(instanceStatuses);
    };

    const loadAndProcessData = async () => {
      try {
        const responseData = await getDoc(idToken, instanceId);
        loadModelData();
        if (Array.isArray(responseData)) {
          setData(responseData);
          const loadedStatuses = responseData.map((item) => item.Status);
          setUniqueStatuses([...new Set(loadedStatuses)]);
        } else {
          console.error(
            "Les données chargées ne sont pas un tableau",
            responseData
          );
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      }
    };

    loadAndProcessData();
  }, []);

  useEffect(() => {
    setConfigData(config);
  }, []);

  // Gère le changement de fichier sélectionné et déclenche le téléchargement
  // Appelle la fonction `handleUpload` avec les paramètres nécessaires pour gérer l'upload du fichier sélectionné
  const handleFileChange = () => {
    handleUpload(
      selectedFile,
      instanceId,
      selectedDocumentId,
      modelId,
      fileExtension,
      attachZip,
      closeModal,
      setSelectedFile
    );
  };

  // Ouvre la fenêtre modale en mettant l'état `modalIsOpen` à `true`
  const openModal = () => {
    setModalIsOpen(true);
  };

  const openModal1 = (item, index) => {
    const iframeURL = `${item.reviewURL}&displayMode=iframe`;
    setCurrentReviewURL(iframeURL);
    setCurrentDocIndex(index);
    setModalIsOpen1(true);
    setCurrentDocName(item.FileName);
  };

  // Ferme la fenêtre modale en mettant l'état `modalIsOpen` à `false`
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
    setCurrentReviewURL("");
  };

  const goToNextReview = () => {
    if (currentDocIndex < filteredData.length - 1) {
      const nextIndex = currentDocIndex + 1;
      const nextItem = filteredData[nextIndex];

      setCurrentDocIndex(nextIndex);
      setCurrentReviewURL(`${nextItem.reviewURL}&displayMode=iframe`);
      setCurrentDocName(nextItem.FileName);
    }
  };

  const goToPreviousReview = () => {
    if (currentDocIndex > 0) {
      const previousIndex = currentDocIndex - 1;
      const previousItem = filteredData[previousIndex];

      setCurrentDocIndex(previousIndex);
      setCurrentReviewURL(`${previousItem.reviewURL}&displayMode=iframe`);
      setCurrentDocName(previousItem.FileName);
    }
  };

  // Appliquer les filtres sur les données sans les filtres de date
  useEffect(() => {
    const filtered = applyFilters(data, filter);
    const sorted = applySort(filtered, sortField, sortOrder);
    setFilteredData(sorted);
  }, [data, filter, sortField, sortOrder]);

  //Gérer le changement de tri
  const handleSort = (field) => {
    toggleSort(field, sortField, setSortField, sortOrder, setSortOrder);
  };

  // Calcule le nombre total de pages nécessaires pour afficher tous les éléments, en fonction du nombre d'éléments par page.
  const pageCount = Math.ceil(data.length / itemsPerPage);

  //Change les couleurs en fonction de l'environnement
  useEffect(() => {
    setEnvironmentColors([
      [setcolorFilter, "colorFilter"],
      [setcolorFilterAction, "colorFilterAction"],
    ]);
  }, []);

  function FilterChange(columnValue, inputValue) {
    setFilter({ ...filter, [columnValue]: inputValue });
  }
  

  const { t } = useTranslation();

  return (
    <>
      <NavBar />
      <div className="page-container" style={{ backgroundColor: "#F7F9F9" }}>
        <div className="filAriane">
          <h6 style={{ textAlign: "left", marginTop: "10px" }}>
            <Link to="/User/">{t("AllModels")}</Link> {" > "}
            {ModelName} {" > "}
            <span
              style={{
                cursor: "pointer",
                textDecoration: hover ? "underline" : "none",
              }}
              onClick={() => navigate("/User/Lots")}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {instanceName}
            </span>
            <CopyToClipboard
              text={instanceId}
              onCopy={() => handleCopy(instanceId)}
            >
              <button
                className="btn btn-outline-primary btn-sm ms-2"
                data-toogle="tooltip"
                title={t("Copy instanceId")}
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </CopyToClipboard>
            {" > "} Documents
          </h6>
          <div>
            <DocStat lots={applySort(applyFilters(data, filter))} />
          </div>
        </div>
        <div
          className="table-responsive"
          style={{
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.5",
          }}
        >
          <div style={{ height: "500px", overflowY: "auto" }}>
            <table
              className="table table-striped"
              style={{ tableLayout: "fixed" }}
            >
              <thead style={{ top: 0, zIndex: 2 }}>
                <tr
                  style={{
                    height: "25px",
                    verticalAlign: "middle",
                    backgroundColor: colorFilter,
                  }}
                >
                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "150px",
                    }}
                  >
                    Actions
                  </th>

                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "390px",
                    }}
                  >
                    {t("Document name")}
                  </th>
                  <th
                    style={{
                      width: "50px",
                      ...styles.TabDoc,
                    }}
                  >
                    Id
                  </th>
                  <th
                    style={{
                      width: "100px",
                      ...styles.TabDoc,
                    }}
                  >
                    {t("Status")}
                  </th>
                  <th style={{ width: "120px", ...styles.TabDoc }}>
                    {t("Complexity")}
                  </th>
                  <th style={{ width: "100px", ...styles.TabDoc }}>{t("Validity")}</th>
                  <th
                    style={{
                      width: "100px",
                      ...styles.TabDoc,
                    }}
                    onClick={() => handleSort("CreationDate")}
                  >
                    {t("Creation")}
                  </th>
                  {Array.isArray(configData.columns) &&
                  configData.columns.length > 0 ? (
                    configData.columns.map((column, index) => (
                      <th
                        key={index}
                        style={{ width: column.size, ...styles.TabDoc }}
                      >
                        {t(column.Name)}
                      </th>
                    ))
                  ) : (
                    <th>{t("Loading")}</th>
                  )}
                </tr>
              </thead>

              <tbody style={{ overflowY: "auto" }}>
                <tr
                  style={{
                    height: "25px",
                    verticalAlign: "middle",
                    top: "25px",
                    zIndex: 2,
                    backgroundColor: colorFilterAction,
                  }}
                >
                  <td style={{ ...styles.TabDoc }}>
                    <input
                      type="checkbox"
                      checked={selectedDocuments.length === data.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDocuments(data.map((item) => item.DocId));
                        } else {
                          setSelectedDocuments([]);
                        }
                      }}
                    />
                  </td>

                  <td
                    style={{
                      ...styles.TabDoc,
                    }}
                  >
                    <center>
                      <input
                        type="text"
                        className="form-control filter-input"
                        onChange={(e) =>
                          setFilter({ ...filter, FileName: e.target.value })
                        }
                        style={{
                          width: "390px",
                          fontSize: "14px",
                          height: "25px",
                        }}
                      />
                    </center>
                  </td>
                  <td
                    style={{
                      ...styles.TabDoc,
                    }}
                  ></td>

                  <td style={{ ...styles.TabDoc }}>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setFilter({ ...filter, Status: e.target.value })
                      }
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        height: "25px",
                        width: "100%",
                        padding: "0px",
                        verticalAlign: "middle",
                      }}
                    >
                      <option value="">{t("All")}</option>
                      {uniqueStatuses.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </td>
                  {/* trie par compléxité */}
                  <td style={{ ...styles.TabDoc }}>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setFilter({ ...filter, Complexite: e.target.value })
                      }
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        height: "25px",
                        width: "100%",
                        padding: "0px",
                        verticalAlign: "middle",
                      }}
                    >
                      <option value="">{t("All")}</option>
                      <option value="Simple">Simple</option>
                      <option value="Moyen">{t("Medium")}</option>
                      <option value="Complexe">{t("Complexe")}</option>
                    </select>
                  </td>
                  {/* trie par Validité */}
                  <td style={{ ...styles.TabDoc }}>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setFilter({ ...filter, isvalid: e.target.value === "" ? null : e.target.value })
                      }
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        height: "25px",
                        width: "100%",
                        padding: "0px",
                        verticalAlign: "middle",
                      }}
                    >
                      <option value="">{t("All")}</option>
                      <option value="true">{t("True")}</option>
                      <option value="false">{t("False")}</option>
                    </select>
                  </td>

                  {/* trie par date */}
                  <td
                    style={{ ...styles.TabDoc }}
                    onClick={() => handleSort("CreationDate")}
                  >
                    {sortField === "CreationDate" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    )}
                  </td>

                  {/* Affichage des colonnes avec configdata et fonctionnalités de tri */}
                  {Array.isArray(configData.columns) &&
                  configData.columns.length > 0 ? (
                    configData.columns.map((column, index) => (
                      <td
                        key={index}
                        style={{ width: column.size, ...styles.TabDoc }}
                      >
                        <center>
                          {column.Filter == "input" ? (
                            <input
                              type="text"
                              placeholder={column.Name}
                              //className='form-control me-2 filter-input'
                              onChange={(e) => FilterChange(column.Value, e.target.value)}
                              style={{
                                width: "98%",
                                fontSize: "14px",
                                height: "25px",
                                textAlign: "center",
                              }}
                            />
                          ) : column.Filter == "select" ? (
                            <select
                              className="form-control"
                              onChange={(e) => FilterChange(column.Value, e.target.value)}
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                height: "25px",
                                width: "100%",
                                padding: "0px",
                                verticalAlign: "middle",
                              }}
                            >
                              <option value="">{t("All")}</option>
                              {uniqueStatuses.map((status) => (
                                <option key={status} value={status}>
                                  {status}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </center>
                      </td>
                    ))
                  ) : (
                    <th>{t("Loading")}</th>
                  )}
                </tr>
                {applySort(applyFilters(data, filter))
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((item, filteredIndex) => (
                    <tr
                      key={item.DocId}
                      style={{ fontSize: "12px", verticalAlign: "middle" }}
                    >
                      <td>
                        {item.Attachmeents &&
                          item.Attachmeents.length > 0 &&
                          item.Attachmeents.filter(
                            (extension) =>
                              extension !== "" && extension !== null
                          ).map((extension, index) => (
                            <button
                              title="download attachement"
                              style={styles.ActionsButton}
                              key={index}
                              className="btn btn-primary"
                              onClick={() =>
                                handleDownload(
                                  item.DocId,
                                  extension,
                                  instanceId,
                                  modelId
                                )
                              }
                            >
                              {extension}
                            </button>
                          ))}
                        <button
                          title={t("upload attachement")}
                          style={styles.ActionsButton}
                          className="btn btn-outline-primary"
                          onClick={() => {
                            openModal();
                            setSelectedDocumentId(item.DocId);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ marginRight: "5px" }}
                          />
                        </button>
                        <button
                          title={t("view document")}
                          style={styles.ActionsButton}
                          className="btn btn-outline-primary"
                          onClick={() => callGetModel(item.DocId, item.ModelId)}
                        >
                          <FontAwesomeIcon
                            icon={faFileAlt}
                            style={{ marginRight: "5px" }}
                          />
                        </button>
                        {item.reviewURL !== undefined &&
                          item.reviewURL !== null && item.Status !== "completed" && (
                            <button
                              title="VideoCodage"
                              style={styles.ActionsButton}
                              data-toogle="tooltip"
                              className="btn btn-outline-primary"
                              onClick={() => openModal1(item, filteredIndex)}
                            >
                              <FontAwesomeIcon
                                icon={faFileSignature}
                                style={{ marginRight: "5px" }}
                              />
                            </button>
                          )}
                        <input
                          type="checkbox"
                          checked={selectedDocuments.includes(item.DocId)}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e,
                              item.DocId,
                              selectedDocuments,
                              setSelectedDocuments
                            )
                          } // Gérer les changements de checkbox
                        />
                      </td>

                      <td title={item.DocId}>{item.FileName}</td>

                      <td title={t("Copy")}>
                        <CopyToClipboard
                          text={item.DocId}
                          onCopy={() => handleCopy(item.DocId)}
                        >
                          <button className="btn btn-outline-primary">
                            <FontAwesomeIcon
                              icon={faCopy}
                              style={{ cursor: "pointer" }}
                            />
                          </button>
                        </CopyToClipboard>
                      </td>

                      <td>{item.Status}</td>

                      <td>
                        {item.Status != "invalid" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {["Simple", "Moyen", "Complexe"].map(
                              (Complexite, index) => {
                                const colors = ["green", "orange", "red"];
                                const isSelected =
                                  item.Complexite === Complexite;
                                const baseColor = colors[index];
                                const backgroundColor = isSelected
                                  ? baseColor
                                  : "#f0f0f0";
                                const color = isSelected ? "white" : baseColor;

                                return (
                                  <button
                                    key={Complexite}
                                    style={{
                                      backgroundColor,
                                      color,
                                      border: `1px solid ${baseColor}`,
                                      padding: "5px 10px",
                                      margin: "0 5px",
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                    }}
                                    onClick={() =>
                                      handleInputChange(
                                        { target: { value: Complexite } },
                                        item.DocId,
                                        "Complexite",
                                        data,
                                        setData,
                                        setSelectedDocuments
                                      )
                                    }
                                  >
                                    {Complexite[0]}
                                  </button>
                                );
                              }
                            )}
                          </div>
                        )}
                        {item.Status == "invalid" && (
                          <div> {item.Complexite} </div>
                        )}
                      </td>

                      <td
                        style={{
                          align: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.Status != "invalid" && (
                          <select
                            className="form-control"
                            value={
                              item.isvalid !== undefined
                                ? item.isvalid
                                  ? "true"
                                  : "false"
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, item.DocId, "isvalid", data, setData, setSelectedDocuments )
                            }
                            style={{
                              width: "100%",
                              textAlign: "center",
                              height: "25px",
                              fontSize: "12px",
                              padding: "1px",
                              color: item.isvalid ? "#006400" : "#8B0000",
                              backgroundColor: item.isvalid
                                ? "#90EE90"
                                : "#FFA07A",
                            }}
                          >
                            <option value="">Valide</option>
                            <option value="true">Vrai</option>
                            <option value="false">Faux</option>
                          </select>
                        )}
                        {item.Status == "invalid" && (
                          <div> {item.isvalid ? "Vrai" : "Faux"} </div>
                        )}
                      </td>

                      <td>{formatDateshort(item.CreationDate)}</td>
                      {itemVars.map(
                        (itemVar) =>
                          configData.displayFields?.[itemVar] && (
                            <td>
                              {""}
                              {item[itemVar]}
                            </td>
                          )
                      )}

                      {Array.isArray(configData.columns) &&
                      configData.columns.length > 0 ? (
                        configData.columns.map((column, index) => {
                          return (
                            <td
                              key={index}
                              style={{
                                textAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item[column.Value]}
                            </td>
                          );
                        })
                      ) : (
                        <td>{t("Loading")}</td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div></div>
        <div className="pagination d-flex justify-content-center mt-2">
          <button
            className="btn btn-danger"
            onClick={() => navigate("/User/Lots")}
          >
            {t("Back")}
          </button>
          <select
            id="itemsPerPage"
            value={itemsPerPage}
            onChange={(event) =>
              handleItemsPerPageChange(event, setItemPerPage)
            }
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          {/* Bouton pour mettre à jour les documents sélectionnés */}
          {Array.from({ length: pageCount }, (_, i) => i + 1).map((number) => (
            <button
              key={number}
              className={`btn btn-outline-secondary ${
                currentPage === number ? "disabled" : ""
              }`}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </button>
          ))}
          <button className='btn btn-primary' onClick={() => handleUpdateSelected(selectedDocuments, data, instanceId, setSelectedDocuments)}>Mettre à jour sélectionnés</button>
        </div>
      </div>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Upload Document Modal"
        >
          <h2>Upload Document</h2>
          <input
            type="file"
            onChange={(e) =>
              handleFileChange(e, setSelectedFile, setFileExtension)
            }
          />
          <button onClick={handleUpload}>Upload</button>
          <button onClick={closeModal}>Close</button>
        </Modal>
      )}
      {}
      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={closeModal1}
        contentLabel="abby url"
      >
        <h3>{currentDocName}</h3>
        <iframe
          src={currentReviewURL}
          style={{ width: "100%", height: "100%" }}
        />
        <button onClick={closeModal1}>Close</button>
        <button onClick={goToPreviousReview} disabled={currentDocIndex <= 0}>
          Previous Document
        </button>
        <button
          onClick={goToNextReview}
          disabled={currentDocIndex >= applyFilters(data, filter).length - 1}
        >
          Next document
        </button>
      </Modal>
    </>
  );
};

export default Plan01Doc;
