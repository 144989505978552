import React from "react";
import { toast } from "react-toastify";


//permet de copier le docid dans le presse papier
export function handleCopy (DocId) {
    toast.success(`ID copié: ${DocId}`, {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };