import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import NavBar from "../../../Navbar/Navbar";
import { setEnvironmentColors } from "../../../Utils/EnvironnementColor";
import { getModel } from "../../../../API/ModelList";
import { VideoCodage } from "../../../../API/VideoCodage";
import { getDocByStatus } from "../../../../API/DocByStatus";
import { docUpdate } from "../../../../API/DocUpdate";
import { parseCustomDate } from "../../../Utils/DateManager";
import { toggleSort, applySort } from "../../../Utils/SortManager";
import { applyFilters } from "../../../Utils/FiltersManager";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config.json";
import styles from "../DefaultCSS.json";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

const ModelsVideoCodage = () => {
  const [colorFilter, setcolorFilter] = useState("#235073");
  const [colorFilterAction, setcolorFilterAction] = useState("#ffffff");
  const [modelsData, setModelsData] = useState([]);
  const [sortField, setSortField] = useState("oldestDocDate");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const uniqueModels = [...new Set(modelsData.map((model) => model.ModelId))];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [currentReviewURL, setCurrentReviewURL] = useState("");
  const [currentDocName, setCurrentDocName] = useState("");
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const [currentInstanceId, setCurrentInstanceId] = useState("");
  const navigate = useNavigate();
  const instanceIdRef = useRef(null);
  const docNameRef = useRef(null);
  const [filter, setFilter] = useState({
    FinalCustomer: "",
    Status: "",
    isvalid: "",
    statusFilter: "",
  });

  useEffect(() => {
    const loadModelsWithDocs = async (statuses) => {
      const idToken = Cookies.get("Token");
      try {
        // Charger les modèles
        const modelList = await getModel(idToken);

        if (!modelList) {
          throw new Error("modelList is undefined");
        }

        // Pour chaque modèle, récupérer les documents par statut
        const modelsWithDocs = await Promise.all(
          modelList.map(async (model) => {
            if (!statuses || !Array.isArray(statuses)) {
              throw new Error("statuses is undefined or not an array");
            }

            const docsByStatus = await Promise.all(
              statuses.map(async (status) => {
                const docs = await getDocByStatus(
                  idToken,
                  status,
                  model.ModelId
                );

                if (!docs) {
                  throw new Error("docs is undefined");
                }

                // Convertir et filtrer les dates valides
                const validDates = docs
                  .map((doc) => parseCustomDate(doc.LastUpdate))
                  .filter((date) => date !== null && !isNaN(date));

                // Trouver la date la plus ancienne
                const oldestDocDate =
                  validDates.length > 0
                    ? validDates.reduce((oldest, current) =>
                        current < oldest ? current : oldest
                      )
                    : null;

                return {
                  status, // Ajouter le statut
                  docsCount: docs.length,
                  oldestDocDate,
                };
              })
            );

            return {
              ...model,
              docsByStatus,
            };
          })
        );

        setModelsData(modelsWithDocs);

        const extractedStatuses = [
          ...new Set(
            modelsWithDocs.flatMap((model) =>
              model.docsByStatus.map((status) => status.status)
            )
          ),
        ];
        setUniqueStatuses(extractedStatuses);
      } catch (error) {
        console.error(
          "Erreur lors du chargement des modèles ou des documents",
          error
        );
      }
    };

    const initializeData = async () => {
      const statuses = config.DEV.statuses;
      if (!statuses || !Array.isArray(statuses)) {
        console.error("statuses is undefined or not an array");
        return;
      }

      await loadModelsWithDocs(statuses);

      // Configurer l'intervalle
      const intervalId = setInterval(() => {
        loadModelsWithDocs(statuses);
      }, 60000);

      // Nettoyer l'intervalle lors du démontage
      return () => clearInterval(intervalId);
    };

    initializeData();
  }, []);

  // Configurer l'intervalle

  useEffect(() => {
    setEnvironmentColors([
      [setcolorFilter, "colorFilter"],
      [setcolorFilterAction, "colorFilterAction"],
    ]);
  }, []);

  //Gérer le changement de tri
  const handleSort = (field) => {
    toggleSort(field, sortField, setSortField, sortOrder, setSortOrder);
  };

  // Appliquer les filtres sur les données sans les filtres de date
  useEffect(() => {
    const filtered = applyFilters(modelsData, filter);
    const sorted = applySort(filtered, sortField, sortOrder);
    setFilteredData(sorted);
  }, [modelsData, filter, sortField, sortOrder]);

  useEffect(() => {
    handleSort("oldestDocDate");
  }, []);

  const { t } = useTranslation();

    const VideoCodageButton = async (ModelId, status) => {
      const idToken = Cookies.get("Token");
      console.log("test statuses : ", status);
      try {
        const data = await VideoCodage(
          idToken,
          status,
          ModelId,
          "in_review"
        );
        console.log(data)
        console.log('instanceID data : ', data.message.InstanceId)
        console.log('docID data : ', data.message.DocumentId)
        if (data.message.reviewURL) {
          openModal1(
            data.message.reviewURL,
            ModelId,
            data.message.DocumentId,
            data.message.InstanceId
          );
        }
      } catch (error) {
        console.error("Erreur lors de l'appel de l'API:", error);
      }
    };

  const openModal1 = (reviewURL, ModelId, docName, instanceId) => {
    console.log("Arguments reçus :", { reviewURL, ModelId, docName, instanceId });
    const iframeURL = `${reviewURL}&displayMode=iframe`;
    setCurrentReviewURL(iframeURL);
    setCurrentDocName(docName);
    setCurrentInstanceId(instanceId);
    instanceIdRef.current = instanceId;
    docNameRef.current = docName;
    setModalIsOpen1(true);
  };
  
  useEffect(() => {
    console.log("currentDocName mis à jour :", currentDocName);
    console.log("currentInstanceId mis à jour :", currentInstanceId);
  }, [currentDocName,currentInstanceId]);

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  // Fonction pour gérer les événements de l'iframe
  const receiveIframeMessage = async (event) => {
    if (event.data?.target === "parent") {
      console.log("Event received from iframe:", event.data);

      switch (event.data.eventName) {
        case "ManualReviewWasPaused":
          await handleTaskStatusUpdate("suspending");
          break;

        default:
          console.warn("Unhandled event:", event.data.eventName);
      }
    }
  };

  const handleTaskStatusUpdate = async (status) => {
    const currentInstanceId = instanceIdRef.current;
    const currentDocName = docNameRef.current;

    console.log("Current InstanceId:", currentInstanceId);
    console.log("Current DocumentId:", currentDocName);

    if (!currentInstanceId || !currentDocName) {
      console.error("InstanceId ou DocumentId manquant !");
      return;
    }

    try {
      const idToken = Cookies.get("Token");
      const response = await docUpdate(
        idToken,
        currentInstanceId,
        currentDocName,
        "normal", // Exemple ou valeur dynamique
        1, // Exemple ou valeur dynamique
        true, // Exemple ou valeur dynamique
        status
      );

      console.log("Statut mis à jour avec succès :", response);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveIframeMessage);
    return () => {
      window.removeEventListener("message", receiveIframeMessage);
    };
  }, []); // Pas de dépendances nécessaires

  return (
    <>
      <NavBar />
      <div className="page-container" style={{ backgroundColor: "#F7F9F9" }}>
        <div
          className="table-responsive"
          style={{
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.5",
          }}
        >
          <div style={{ height: "500px", overflowY: "auto" }}>
            <table
              className="table-striped"
              style={{ tableLayout: "fixed", width: "100%" }}
            >
              <thead style={{ top: 0, zIndex: 2 }}>
                <tr
                  style={{
                    height: "25px",
                    verticalAlign: "middle",
                    backgroundColor: colorFilter,
                  }}
                >
                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "150px",
                    }}
                  >
                    {t("Models")}
                  </th>
                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "150px",
                    }}
                  >
                    {t("Status")}
                  </th>
                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "150px",
                    }}
                    onClick={() => handleSort("docsCount")}
                  >
                    {t("Number of docs in VideoCoder")}
                  </th>
                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "150px",
                    }}
                    onClick={() => handleSort("oldestDocDate")}
                  >
                    Min({t("LastUpdate")})
                  </th>
                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "150px",
                    }}
                  ></th>
                </tr>
                <tr
                  style={{
                    height: "25px",
                    verticalAlign: "middle",
                    top: "25px",
                    zIndex: 2,
                    backgroundColor: colorFilterAction,
                  }}
                >
                  <th style={styles.TabDoc}>
                    <select
                      className="form-select filter-select"
                      onChange={(e) =>
                        setFilter({ ...filter, ModelId: e.target.value })
                      }
                      style={{
                        width: "125px",
                        textAlign: "left",
                        height: "31px",
                      }}
                    >
                      <option value="">Models</option>
                      {uniqueModels.map((model) => (
                        <option key={model} value={model}>
                          {model}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th style={styles.TabDoc}>
                    <select
                      className="form-select filter-select"
                      onChange={(e) =>
                        setFilter({ ...filter, statusFilter: e.target.value })
                      }
                      style={{
                        width: "125px",
                        textAlign: "left",
                        height: "31px",
                      }}
                    >
                      <option value="">{t("Status")}</option>
                      {uniqueStatuses.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th
                    style={styles.TabDoc}
                    onClick={() => handleSort("docsCount")}
                  >
                    {sortField === "docsCount" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    )}
                  </th>
                  <th
                    style={styles.TabDoc}
                    onClick={() => handleSort("oldestDocDate")}
                  >
                    {sortField === "oldestDocDate" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    )}
                  </th>
                  <th style={styles.TabDoc}></th>
                </tr>
              </thead>
              <tbody>
                {applySort(
                  applyFilters(modelsData, filter),
                  sortField,
                  sortOrder
                )
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((model, filteredIndex) => (
                    <React.Fragment key={model.ModelId}>
                      {model.docsByStatus.map((statusData) => (
                        <tr key={`${model.ModelId}-${statusData.status}`}>
                          <td>{model.ModelId}</td>
                          <td>{statusData.status}</td>
                          <td>{statusData.docsCount}</td>
                          <td>
                            {statusData.oldestDocDate
                              ? statusData.oldestDocDate.toLocaleDateString()
                              : t("No date")}
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-primary"
                              onClick={() => VideoCodageButton(model.ModelId, statusData.status)}
                              disabled={statusData.status === "in_review"}
                            >
                              VideoCoder
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={closeModal1}
        contentLabel="Manual Review"
      >
        <h3>{currentDocName}</h3>
        <iframe
          src={currentReviewURL}
          style={{ width: "100%", height: "80vh" }}
          title="Manual Review Iframe"
        />
        <button onClick={closeModal1}>Close</button>
      </Modal>
    </>
  );
};

export default ModelsVideoCodage;
