import React from "react";
import config from "../config.json"


//permet de mettre une couleur dans le config.json en global sur un element
export const setEnvironmentColors = (setColorFunctions) => {
  const environment = process.env.REACT_APP_DEV_NAME;
  if (config[environment]) {
    const colors = config[environment];
    setColorFunctions.forEach(([setColor, colorKey]) => {
      if (colors[colorKey]) {
        setColor(colors[colorKey]);
      }
    });
  } else {
    console.warn("Environnement non configuré dans config.json");
  }
};
