/** @format */
import apiConfig from "../config/_config"; // api.js
import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API

export async function getDocByStatus(token, Status, ModelId = null) {
  const apiUrl = `${API_BASE_URL}/document/list/bystatus`;

  const body = ModelId ? { ModelId: ModelId } : {};
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        body,
        Status: Status,
        ModelId: ModelId,
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.message.list;
  } catch (error) {
    throw new Error(error);
  }
}
