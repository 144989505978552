import apiConfig from "../config/_config";
import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export async function VideoCodage(token, Status, ModelId, UpdateStatus) {
  console.log("status", Status)
  console.log("modelid", ModelId)
  console.log("updatestatus", UpdateStatus)
    const apiUrl = `${API_BASE_URL}/document/list/toreview`;
    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({ Status, ModelId, UpdateStatus })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data; // Retournez les données récupérées pour qu'elles puissent être utilisées
    } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Relancez l'erreur pour qu'elle soit capturée dans le composant
    }
}
