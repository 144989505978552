import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./Locale/eng.json"),
    },
    fr: {
      translation: require("./Locale/fr.json"),
    },
  },
  lng: localStorage.getItem('i18nextLng') || 'fr', // Langue par défaut
  fallbackLng: "en", // Langue de repli
  interpolation: {
    escapeValue: false, // React protège automatiquement contre les failles XSS
  },
});

export default i18n;