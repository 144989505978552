/** @format */

import React, { useRef, useState, useEffect, useCallback } from "react";
import { createInstanceAndDocs } from "../../../API/UploadToS3";
import { getModel } from "../../../API/ModelList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../Navbar/Navbar";
import { faFile, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import Cookies from "js-cookie";
import { json } from "@codemirror/lang-json";
import { Modal, Spinner, Alert, Button, Card, Form } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { getInstance } from "../../../API/DocQuery";
import styles from "./ImportCSS.json";
import "../Import/Import.css";
import { useTranslation } from "react-i18next";

export default function Import() {
  const fileInputRef = useRef(null);
  const [instanceName, setInstanceName] = useState("INVOIC");
  const [sFinalCustomer, setSFinalCustomer] = useState("");
  const [fileNumber, setFileNumber] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("All");
  const [showJSONEditor, setShowJSONEditor] = useState(false);
  const [value, setValue] = useState("");
  const idToken = Cookies.get("Token");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [droppedFileNames, setDroppedFileNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [useExistingInstance, setUseExistingInstance] = useState(false);
  const [existingInstanceName, setExistingInstanceName] = useState("");
  const [instanceList, setInstancesList] = useState([]);
  const [iExpectedDocs, setIExpectedDocs] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getModel(idToken)
      .then((data) => {
        setModelList(data);
      })
      .catch((error) => {
        console.error("Error fetching model list:", error);
      });

    getInstance(idToken)
      .then((instances) => {
        setInstancesList(instances);
        console.log("instance : ", instanceList);
      })
      .catch((error) => {
        console.error("Error Fetching instances list: ", error);
      });
  }, [idToken]);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    updateFilesState(files);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const onChange = useCallback((val) => {
    JSON.stringify(val);
    setValue(val);
  }, []);

  const handleNext = () => {
    if (step === 0) {
      // Étape 0 : Vérifier si un modèle est sélectionné
      if (selectedModel === "All") {
        setError(t("Please select a model."));
      } else {
        setStep(1);
        setError("");
      }
    } else if (step === 1) {
      console.log(iExpectedDocs);
      if (!useExistingInstance) {
        if (!sFinalCustomer.trim()) {
          setError(t("Please enter a name for the new instance."));
          return;
        }
        if (iExpectedDocs <= 0) {
          setError(
            t(
              "Please put an expected number of documents for this new instance."
            )
          );
          return;
        }
      }
      if (useExistingInstance && !existingInstanceName) {
        setError(t("Please select an existing instance."));
        return;
      }
      setStep(2);
      setError("");
    } else if (step === 2) {
      // Étape 2
      setStep(3);
      setError("");
    } else if (step === 3) {
      // Étape 3 : Vérifier les fichiers ajoutés
      if (droppedFileNames.length === 0) {
        setError(t("You need to add files to your instance."));
      } else {
        setStep(4);
        setError("");
      }
    } else if (step === 4) {
      // Étape 4 : Vérifier le nombre de fichiers
      if (!fileNumber || isNaN(fileNumber)) {
        setError(t("Please enter a valid number of files."));
      } else {
        setStep(5);
        setError("");
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();

    if (droppedFileNames.length >= iExpectedDocs) {
      return;
    }

    const files = Array.from(e.dataTransfer.files);
    const availableSlots = iExpectedDocs - droppedFileNames.length;

    const filesToAdd = files.slice(0, availableSlots);

    updateFilesState(filesToAdd);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileDelete = () => {
    setSelectedFiles([]);
    setDroppedFileNames([]);
  };

  const handleOpenJSONEditor = () => {
    setShowJSONEditor(true);
  };

  const handleCloseJSONEditor = () => {
    setShowJSONEditor(false);
  };

  const handleFileSubmit = async () => {
    if (
      (!useExistingInstance &&
        (!sFinalCustomer || !selectedModel || !droppedFileNames)) ||
      (useExistingInstance &&
        (!existingInstanceName || !selectedModel || !droppedFileNames))
    ) {
      setError("Veuillez remplir tous les champs.");
      return;
    }
    setLoading(true);
    try {
      let response;
      const numberOfDocs = selectedFiles.length;
      const adjustedIExpectedDocs =
        iExpectedDocs > 0 ? iExpectedDocs : numberOfDocs;
      if (useExistingInstance) {
        const instanceInfo = instanceList.find(
          (i) => i.kInstanceId === existingInstanceName
        );
        const docsAlreadyInInstance = instanceInfo?.docsCount || 0;
        const docsToAdd = selectedFiles.length;
        const allowedDocs = instanceInfo?.allowedDocs || Infinity;
        if (docsAlreadyInInstance + docsToAdd > allowedDocs) {
          setError(
            `L'instance sélectionnée permet seulement ${allowedDocs} documents. Actuellement ${docsAlreadyInInstance}, vous essayez d'en ajouter ${docsToAdd}.`
          );
          setLoading(false);
          return;
        }
        response = await createInstanceAndDocs(
          existingInstanceName,
          selectedModel,
          selectedFiles,
          idToken,
          false,
          value
        );
        console.log("Ajout réussi :", response);
      } else {
        response = await createInstanceAndDocs(
          instanceName,
          selectedModel,
          selectedFiles,
          idToken,
          true,
          value,
          adjustedIExpectedDocs,
          sFinalCustomer
        );
        console.log("Création réussie :", response);
      }
      setApiResponse(response);
      setLoading(false);
      setStep(5);
      setSFinalCustomer("");
      setExistingInstanceName("");
      setFileNumber(null);
      setSelectedModel("All");
      setSelectedFiles([]);
      setDroppedFileNames([]);
    } catch (error) {
      console.error("Erreur lors de l'ajout des fichiers :", error);
      setLoading(false);
      setError("Une erreur s'est produite lors de l'ajout des fichiers.");
    }
  };

  const showStepErr = step === -1;
  const showStep0 = step >= 0;
  const showStep1 = step >= 1;
  const showStep2 = step >= 2;
  const showStep3 = step >= 3;
  const showStep4 = step >= 4;
  const showStep5 = step === 5;

  useEffect(() => {
    if (showStep2) {
      handleOpenJSONEditor();
    }
  }, [showStep2]);

  const updateFilesState = (newFiles) => {
    const existingFileNames = new Set(droppedFileNames); // Set contenant les noms actuels
    const uniqueFiles = newFiles.filter(
      (file) => !existingFileNames.has(file.name)
    ); // Filtre des nouveaux fichiers uniques

    setSelectedFiles((prevFiles) => {
      const fileSet = new Set([...prevFiles, ...uniqueFiles]); // Garantir l'unicité avec Set
      return Array.from(fileSet); // Convertir en tableau
    });

    setDroppedFileNames((prevNames) => {
      const nameSet = new Set([
        ...prevNames,
        ...uniqueFiles.map((file) => file.name),
      ]);
      return Array.from(nameSet);
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <NavBar />
      <div style={{ backgroundColor: "#f2f0f0", padding: "20px" }}>
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{ display: "flex", flexWrap: "wrap", alignItems:"center" }}
        >
          {loading && (
            <Modal show={true} centered>
              <Modal.Body className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">{t("Loading")}...</span>
                </Spinner>
                <h4>{t("Processing in progress")}...</h4>
              </Modal.Body>
            </Modal>
          )}
          {!loading && (
            <>
              {showStepErr && (
                <Card className="text-center" style={styles.CardStyles}>
                  <Card.Body>
                    {t("Error in processing, please try again")}
                    <Button
                      className="btn-success"
                      onClick={() => navigate("/User/")}
                    >
                      {t("Return instance")}
                    </Button>
                    <Button className="btn-success" onClick={() => setStep(0)}>
                      {t("Add instance")}
                    </Button>
                  </Card.Body>
                </Card>
              )}
              {showStep0 && (
                <Card
                  className="text-center"
                  style={{
                    ...styles.CardStyles,
                    height: "173px",
                  }}
                >
                  <Card.Body>
                    <Card.Title style={{ color: "#3c6382" }}>
                      {t("Choose a model")}
                    </Card.Title>
                    <Form.Select
                      className="FormSelect"
                      value={selectedModel}
                      onChange={handleModelChange}
                    >
                      <option value="All">{t("Choice of model")}</option>
                      {modelList.map((model) => (
                        <option key={model.ModelId} value={model.ModelId}>
                          {model.ModelName}
                        </option>
                      ))}
                    </Form.Select>
                    <Button
                      className="btn"
                      style={styles.NextButton}
                      onClick={handleNext}
                    >
                      {t("Next")}{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{ marginLeft: "10px" }}
                      />
                    </Button>
                  </Card.Body>
                </Card>
              )}
              {showStep1 && (
                <>
                  <FontAwesomeIcon icon={faArrowRight} className="arrowStep" />
                  <Card
                    className="text-center"
                    style={{
                      ...styles.CardStyles,
                      height: "auto",
                    }}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-center mb-3">
                        <Button
                          className={`btn ${
                            !useExistingInstance ? "btn-success" : "btn-light"
                          }`}
                          onClick={() => setUseExistingInstance(false)}
                        >
                          {t("New instance")}
                        </Button>
                        <Button
                          className={`btn ${
                            useExistingInstance ? "btn-success" : "btn-light"
                          }`}
                          onClick={() => setUseExistingInstance(true)}
                          style={{ marginLeft: "10px" }}
                        >
                          {t("existing instance")}
                        </Button>
                      </div>

                      {!useExistingInstance ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Form.Control
                              style={styles.FormControl}
                              type="text"
                              placeholder={t("Enter the end customer name")}
                              value={sFinalCustomer}
                              onChange={(e) =>
                                setSFinalCustomer(e.target.value)
                              }
                            />
                            <Form.Control
                              style={{
                                ...styles.FormControl,
                                marginTop: "5px",
                                marginBottom: "10px",
                              }}
                              type="number"
                              value={iExpectedDocs === 0 ? "" : iExpectedDocs} // Affiche vide si la valeur est 0
                              min="0"
                              placeholder={t("Enter the number of documents expected")}
                              onChange={
                                (e) =>
                                  setIExpectedDocs(Number(e.target.value) || 0) // Remplace une valeur vide par 0
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <Form.Select
                          style={{ marginBottom: "10px" }}
                          value={existingInstanceName}
                          onChange={(e) => {
                            const selectedInstance = instanceList.find(
                              (instance) =>
                                instance.kInstanceId === e.target.value
                            );
                            setExistingInstanceName(e.target.value);
                            setIExpectedDocs(
                              selectedInstance
                                ? selectedInstance.iExpectedDocs
                                : 0
                            );
                            setDroppedFileNames(
                              selectedInstance
                                ? Array(selectedInstance.iCreatedDocs).fill(
                                    t("doc already created")
                                  )
                                : []
                            );
                          }}
                        >
                          <option value="">
                            {t("Select an existing instance")}
                          </option>
                          {instanceList
                            .filter((instance) => {
                              console.log("Instance:", instance);
                              console.log(
                                "iExpectedDocs:",
                                instance.iExpectedDocs
                              );
                              console.log("docsCount:", instance.iCreatedDocs);
                              return (
                                instance.iExpectedDocs > instance.iCreatedDocs
                              );
                            })
                            .map((instance) => (
                              <option
                                key={instance.kInstanceId}
                                value={instance.kInstanceId}
                              >
                                {instance.kInstanceId}
                              </option>
                            ))}
                        </Form.Select>
                      )}

                      <Button
                        className="btn"
                        style={styles.NextButton}
                        onClick={handleNext}
                      >
                        {t("Next")}{" "}
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </Card.Body>
                  </Card>
                </>
              )}
              {showStep2 && (
                <>
                  <FontAwesomeIcon icon={faArrowRight} className="arrowStep" />
                  <Card
                    className="text-center"
                    style={{
                      ...styles.CardStyles,
                      height: "173px",
                    }}
                  >
                    <Card.Body>
                      {showJSONEditor && (
                        <>
                          <Card.Title style={{ color: "#3c6382" }}>
                            {t("Adding JSON")}
                          </Card.Title>
                          <div className="jsonEditor">
                            <CodeMirror
                              value={value}
                              height="38px"
                              theme={dracula}
                              extensions={[json({ jsx: true })]}
                              onChange={onChange}
                            />
                          </div>
                        </>
                      )}
                      <Button
                        className="btn"
                        style={{
                          ...styles.NextButton,
                          marginTop: "30px",
                        }}
                        onClick={handleNext}
                      >
                        {t("Next")}{" "}
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </Card.Body>
                  </Card>
                </>
              )}
              {showStep3 && (
  <>
    <FontAwesomeIcon icon={faArrowRight} className="arrowStep" />
    <Card
      className="text-center"
      style={{
        ...styles.CardStyles,
        height: "auto",
      }}
      onDragOver={(event) => {
        if (droppedFileNames.length < iExpectedDocs) {
          handleDragOver(event);
        }
      }}
      onDrop={handleDrop}
    >
      <Card.Body>
        <Card.Title style={{ color: "#3c6382" }}>
          {t("AddFiles")}
        </Card.Title>
        {t("FileChose")}
        <div style={{ color: "#3c6382", marginBottom: "10px", maxHeight: "80px", overflowY: "auto", border: "1px solid #ccc", padding: "10px" }}>
          <ul>
            {droppedFileNames.map((fileName, index) => (
              <li key={`${fileName}-${index}`}>{fileName}</li>
            ))}
          </ul>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(event) => {
            const files = Array.from(event.target.files);
            if (files.length + droppedFileNames.length <= iExpectedDocs) {
              handleFileUpload(event);
            }
          }}
          multiple
        />
        <p>{t("Files drop")} : {droppedFileNames.length} / {t("Documents expected")} : {iExpectedDocs}</p>
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-primary"
            onClick={handleFileSelect}
            style={{ marginRight: "10px" }}
            disabled={droppedFileNames.length >= iExpectedDocs}
          >
            <FontAwesomeIcon icon={faFile} /> {t("File choice")}
          </Button>
          <Button
            className="btn btn-danger"
            onClick={handleFileDelete}
          >
            <FontAwesomeIcon icon={faTrash} /> {t("Delete")}
          </Button>
        </div>
        <div>
          <Button
            className="btn"
            style={{
              ...styles.NextButton,
              marginTop: "15px",
            }}
            onClick={handleNext}
          >
            {t("Next")}{" "}
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ marginLeft: "10px" }}
            />
          </Button>
        </div>
      </Card.Body>
    </Card>
  </>
)}


              {showStep4 && (
                <>
                  <FontAwesomeIcon icon={faArrowRight} className="arrowStep" />
                  <Card
                    className="text-center"
                    style={{
                      ...styles.CardStyles,
                      height: "100px",
                    }}
                  >
                    <CardBody>
                      <Card.Title style={{ color: "#3c6382" }}>
                        {t("Validate instance creation")}
                      </Card.Title>
                      <Button
                        className="btn btn-success"
                        onClick={handleFileSubmit}
                        style={{ marginRight: "10px" }}
                      >
                        <FontAwesomeIcon icon={faCheck} /> UP
                      </Button>
                    </CardBody>
                  </Card>
                </>
              )}
              {showStep5 && (
                <Alert
                  variant="success"
                  style={{ width: "550px", margin: "20px" }}
                >
                  {t("Treatment completed successfully")}
                  <div className="d-flex justify-content-between">
                    <Button
                      className="btn-success"
                      onClick={() => navigate("/User/")}
                    >
                      {t("Return instance")}
                    </Button>
                    <Button className="btn-success" onClick={() => setStep(0)}>
                      {t("Add instance")}
                    </Button>
                  </div>
                </Alert>
              )}
              {error && (
                <Alert
                  variant="danger"
                  style={{ width: "550px", margin: "20px", padding: "5%" }}
                >
                  {error}
                </Alert>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
